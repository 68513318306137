<template>
  <div class="camera3">
    <div class="size">
      <span>宽</span>
      <Input type="text" v-model="width" @on-change="setCanvae" />
      <span>高</span>
      <Input type="text" v-model="height" @on-change="setCanvae" />
    </div>
    <div class="camera_k">
      <div id="CameraCtl2">
        <Icon type="ios-loading" class="demo-spin-icon-load" size="40" />
        <div class="rotate_mk" :class="isRotate ? 'rotate_90' : 'rotate_0'">
          <video v-show="isStart" ref="video" class="canvas rotate" :width="width" :height="height" autoplay></video>
          <div class="hand_mb"></div>
        </div>
      </div>
      <canvas ref="canvas" class="picture-canvas" :width="height" :height="width"></canvas>
      <canvas ref="canvas2" class="picture-canvas" :width="width" :height="height"></canvas>

      <!-- 拍照 -->
      <div class="btn_g">
        <div class="btn captureImage_btn" @click="takePhoto">
          <Tooltip content="拍照" placement="top">
            <Icon type="md-camera" />
          </Tooltip>
        </div>
      </div>
      <Button type="primary" @click="routerFun">{{ isRotate ? "不旋转" : "旋转" }}</Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    showWin: {
      type: Boolean,
      default: true,
    },
    patientInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters(["cameraInfo3"]),
  },
  watch: {
    showWin(newValue) {
      this.savePatientInfo();
      if (newValue) {
        this.init();
      } else {
        this.closeCamera();
      }
    },
  },
  data() {
    return {
      showPicInput: false,
      input_picurl: "",
      // width: 3264,
      // height: 2448,

      width: 3840,
      height: 2160,
      MediaStreamTrack: null,
      imgData: null,
      video: null,
      canvas: null,
      isStart: false,
      loading: false,
      rotate: "rotate_0",
      isRotate: false,
      // rotate: "rotate_90",
      // rotate: "rotate_-90",
    };
  },
  methods: {
    routerFun() {
      this.isRotate = !this.isRotate;
      this.setCanvae();
    },
    // 开始预览
    getMedia() {
      let constraints = {
        video: { width: this.width, height: this.height },
        audio: false,
      };

      // 获取报像头列表
      navigator.mediaDevices.enumerateDevices().then(function (devices) {
        // console.log("-----------");
        console.log(devices);
      });
      let promise = navigator.mediaDevices.getUserMedia(constraints);
      promise.then((MediaStream) => {
        this.MediaStreamTrack = typeof MediaStream.stop === "function" ? MediaStream : MediaStream.getTracks()[0];
        // console.log("--------->", MediaStream);
        this.video.srcObject = MediaStream;
        // this.video.play();
        this.isStart = true;
      });
    },
    // 初始化
    init() {
      this.loading = false;
      this.imgData = null;
      // if (this.isStart) {
      //   this.video.play();
      // } else {
      // }
      this.getMedia();
      this.clearCanvas();
    },
    // 拍照
    takePhoto() {
      this.clearCanvas();
      let ctx = this.canvas.getContext("2d");
      if (this.isRotate) {
        ctx.clearRect(0, 0, this.height, this.width);
        // ctx.translate(0, this.canvas.height);
        ctx.translate(0, this.canvas.height);
        ctx.rotate((270 * Math.PI) / 180);
        ctx.drawImage(this.video, 0, 0, this.width, this.height);
      } else {
        ctx.clearRect(0, 0, this.width, this.height);
        // ctx.translate(0, this.canvas.height);
        // ctx.rotate((90 * Math.PI) / 180);
        ctx.drawImage(this.video, 0, 0, this.width, this.height);
      }
      this.imgData = this.canvas.toDataURL("image/jpeg");
      this.handleDownloadQrIMg(this.imgData);
      console.log(this.imgData);
    },
    //imageBase64是后台传回来的base64数据
    handleDownloadQrIMg(imgUrl) {
      // 这里是获取到的图片base64编码,这里只是个例子哈，要自行编码图片替换这里才能测试看到效果
      // const imgUrl = `data:image/jpg;base64,${imageBase64}`;
      // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
      if (window.navigator.msSaveOrOpenBlob) {
        const bstr = atob(imgUrl.split(',')[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const blob = new Blob([u8arr]);
        window.navigator.msSaveOrOpenBlob(blob, 'filename.jpg');
      } else {
        // 这里就按照chrome等新版浏览器来处理
        const a = document.createElement('a');
        a.href = imgUrl;
        a.setAttribute('download', 'filename.jpg');
        a.click();
      }
    },

    // 清空画布
    clearCanvas() {
      this.canvas.getContext("2d");
      // eslint-disable-next-line no-self-assign
      this.canvas.height = this.canvas.height;
    },
    // 关闭摄像头
    closeCamera() {
      this.isStart = false;
      this.video.pause();
      this.MediaStreamTrack && this.MediaStreamTrack.stop();
    },

    savePatientInfo() {
      let obj = {
        patientCode: this.patientInfo.patientCode,
        hospitalCode: this.patientInfo.hospitalCode,
      };
      localStorage.setItem("patientInfo", JSON.stringify(obj));
    },

    // 拍照结果
    submitFun() {
      if (this.loading) return;
      let fileName =
        this.patientInfo.hospitalCode +
        this.patientInfo.patientCode +
        this.common.parseTime(new Date(), "{y}{m}{d}{h}{i}{s}.jpg");
      var fileObj = this.common.base64ToFile(this.imgData, fileName); //base64数据转为文
      this.upLoadImage([
        {
          key: "multipartFiles",
          file: fileObj,
        },
      ]);
    },

    // 上传图片
    upLoadImage(obj) {
      // typeId值，0病历图片 1手相图片 2面相图片 3穴位图片 4伙伴图片 5药品图片 6其他图片
      obj.push({
        key: "typeId",
        file: 1,
      });
      this.loading = true;
      this.api.u_request(
        "fileUploadNew",
        (res) => {
          if (this.common.isError(res)) return;
          this.addMedicalRecordScan({
            base64: this.imgData,
            path: res.result,
          });
        },
        obj
      );
    },

    // 保存病历扫描图片信息
    addMedicalRecordScan(imageInfo) {
      let obj = {
        patientCode: this.cameraInfo3.info.patientCode,
        registerCode: this.cameraInfo3.info.registerCode,
        medicalRecordScanDetailDTOList: [
          {
            picUrl: imageInfo.path,
            picType: 1, // (0.病历扫描 1.手相)
          },
        ],
        registerDatetime: this.cameraInfo3.info.registerDateTime,
      };

      this.api.u_request(
        "addMedicalRecordScan",
        (res) => {
          this.loading = false;

          this.$store.commit("sendLog", {
            remarks: "保存病历扫描图片信息",
            apiName: "addMedicalRecordScan",
            inParams: obj,
            outParams: res,
          });
          if (this.common.isError(res)) return;
          let aidId = res.result.details[0]["aidId"];

          this.$emit("showPic", {
            base64: imageInfo.base64,
            path: res.result,
            aidId,
          });

          // 标记已拍手相
          this.$store.commit("setPicLabeling", {
            id: obj.registerCode,
            value: 1,
          });
        },
        obj
      );
    },

    setCanvae() {
      this.canvas = this.isRotate ? this.$refs.canvas : this.$refs.canvas2;
      this.init();

      setTimeout(() => {
        console.dir(this.video);
        console.log(
          this.video.videoHeight,
          this.video.videoWidth
        );
      }, 1000);
    }
  },
  mounted() {
    this.video = this.$refs.video;
    this.setCanvae();
    if (this.showWin) {
      this.savePatientInfo();
      this.init();
    }
  },
  beforeDestroy() {
    this.closeCamera();
  },
};
</script>

<style>
.camera3 .patientInfo {
  position: absolute;
  top: 14px;
  left: 110px;
  padding: 0;
  background: none;
}

.camera3 .patientInfo span {
  padding-left: 10px;
  color: #853a2f;
}

.camera3 .patientInfo span.name {
  font-weight: bold;
  font-size: 15px;
}

.camera3 .ivu-modal-body {
  display: flex;
}

.camera3 #CameraCtl2 {
  width: 450px;
  height: 600px;
}

.camera3 #CameraCtl2 img {
  width: 450px;
  height: 600px;
}

.camera3 #CameraCtl2 .demo-spin-icon-load {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
}

.boot_g {
  text-align: center;
  padding-top: 15px;
}

.boot_g .ivu-btn {
  padding: 3px 40px;
  height: auto;
  font-size: 14px;
}

.camera_status {
  position: absolute;
  background: #ffffff;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.camera_status .camera_status_content {
  width: 320px;
  line-height: 40px;
  font-size: 15px;
}

.camera_status .c_s_c_title {
  font-size: 18px;
  position: relative;
}

.camera_status .c_s_c_title .ivu-icon {
  position: absolute;
  color: #f00;
  left: -30px;
  top: 8px;
  font-size: 24px;
}

.camera_status .ivu-btn-primary {
  border: none;
  font-size: 12px;
}

.camera3 .u_pics {
  padding-top: 15px;
}

.camera3 .u_pics .u_pic {
  display: inline-block;
  width: 60px;
  height: 60px;
  border: 1px solid #cccccc;
  padding: 3px;
  position: relative;
  margin: 0 10px 10px 0;
}

.camera3 .u_pics .u_pic .date {
  display: none;
}

.camera3 .u_pics .u_pic .date {
  display: block;
}

.camera3 .u_pics .u_pic img {
  width: 100%;
  height: 100%;
}

.camera3 .u_pics .u_pic .del_btn {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  color: #f00;
  cursor: pointer;
  opacity: 0.7;
}

.camera3 .u_pics .u_pic .del_btn:hover {
  opacity: 1;
}

.camera3 .down_btn {
  float: left;
  color: #333333;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.camera3 .timeout_tip {
  flex-direction: column;
}

.camera3 .timeout_tip button {
  margin-top: 20px;
}

.camera3 .u_dropdown {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 11;
  text-align: center;
  background: rgba(255, 255, 255, 0.3);
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.3);
  border-right-width: 0;
  border-radius: 15px 0 0 15px;
}

.camera3 .u_dropdown .ivu-icon-md-more {
  font-size: 20px;
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;
  padding-left: 5px;
  box-sizing: border-box;
  line-height: 30px;
  text-align: center;
  color: #000;
}

.camera3 .modal_close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.camera3 .u_pics {
  padding: 0 0 0 15px;
  height: 600px;
  display: flex;
  flex-direction: column;
}

.camera3.emptyPic .u_pics {
  display: none;
}

.camera3 .u_pics .u_pic_k {
  height: 100%;
  overflow-y: auto;
}

.camera3 .u_pics .pic_title {
  text-align: center;
  width: 110px;
  background: #eccfad;
  line-height: 24px;
  font-size: 12px;
  margin-bottom: 10px;
}

.camera3 .u_pics .u_pic {
  width: 110px;
  height: 130px;
  text-align: center;
}

.camera3 .u_pics .u_pic .img_k {
  width: 100%;
  height: 100%;
}

.camera3 .u_pics .u_pic .img_k {
  width: 102px;
  height: 102px;
}

.camera3 .u_pics .u_pic .date {
  font-size: 10px;
  font-style: inherit;
}

.camera3 .picture-canvas {
  visibility: hidden;
  position: absolute;
}

.camera3 .input-picurl {
  display: flex;
  background: #ffffff;
  border: 1px solid #ccc;
  padding: 4px 8px;
  height: 42px;
  width: 100%;
  position: absolute;
  top: -55px;
  right: 0;
}

.camera3 .submitFun4 {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 100;
}

.size {
  display: flex;
  align-items: center;
  position: absolute;
  top: -40px;
  width: 100%;
}

.size>span {
  padding: 0 10px;
}
</style>
