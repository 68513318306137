<template>
  <div class="hand">
    <div class="hand2 hand2_con">
      <camera32 />
    </div>
  </div>
</template>

<script>
import camera32 from '@/components/camera/camera3_2.vue'

export default {
  name: 'HomeView',
  components: {
    camera32,
  }
}
</script>
<style>
@import url("@/components/camera/camera3.css");

.hand {
  width: 450px;
  height: 600px;
  position: relative;
}

.hand2 {
  width: 450px;
  height: 600px;
  border: 1px solid #ddd;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -300px 0 0 -225px;
}
</style>
